<template>
  <Acl :permissions="['posts-index']">
    <b-row no-gutters class="align-items-end justify-content-between mb-3">
      <b-col lg="3" class="text-right">
        <a-form :form="form" @submit.prevent="search" layout="inline">
          <a-input-search v-model="q" placeholder="Search Posts"></a-input-search>
        </a-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="px-3 card">
          <a-table
            :row-selection="{ selectedRowKeys: selectedPosts, onChange: onSelectChange }"
            :loading="loading"
            :columns="columns"
            :dataSource="posts"
            :class="$style.table"
            :pagination="pagination"
            :scroll="{ x: 1000 }"
            @change="tableChanged">
            <template slot="articleTitle" slot-scope="title, item">
              <b-row no-gutters>
                <b-col v-if="item.imageMedia.length || item.image_media" cols="3" class="pr-3">
                  <img
                    class="w-100 rounded"
                    :src="`${item.image_media ? item.image_media.url : item.imageMedia[0].url}?tr=w-50,h-50`"
                    :srcset="`${item.image_media ? item.image_media.url : item.imageMedia[0].url}?tr=w-50,h-50 1x, ${item.image_media ? item.image_media.url : item.imageMedia[0].url}?tr=w-100,h-100 2x`"
                    :alt="item.image_media ? item.image_media.alt : item.imageMedia[0].alt">
                </b-col>
                <b-col>
                  <h2 class="mb-0 h6">
                    <router-link :title="`Edit: ${title}`" class="text-gray-6" :to="{ path: `posts/edit/${item.id}`}">
                      {{ title }}
                    </router-link>
                  </h2>
                  <small :class="item.is_published ? 'text-success' : item.scheduled_at ? 'text-info' : 'text-warning'">{{ item.is_published ? 'Published' : item.scheduled_at ? 'Scheduled' : 'Draft' }}</small>
                </b-col>
              </b-row>
            </template>
            <template slot="createdBy" slot-scope="user">
              {{ user.full_name }}
            </template>
            <template slot="portal" slot-scope="portals">
              <span v-for="portal in portals" :key="portal.id">{{ portal.title }}</span>
            </template>
            <template slot="categories" slot-scope="categories">
              {{ categories.map((category) => {
                return category.title
              }).join(', ') }}
            </template>
            <template slot="tags" slot-scope="tags">
              <div v-if="tags.length">
                <span class="badge badge-info mr-1" v-for="tag in tags" :key="tag.id">{{ tag.name }}</span>
              </div>
              <span v-else>-</span>
            </template>
            <template slot="date" slot-scope="date, item">
              {{ item.published_at ? 'Published at' : 'Scheduled at' }} {{ item.published_at ? item.published_at : item.scheduled_at | moment("DD MMM YYYY")}}
              <span class="text-muted small">{{ item.published_at ? item.published_at : item.scheduled_at | moment("h:mm A") }}</span>
              <p class="mb-0 small">by <span class="text-primary">{{ item.createdBy.full_name }}</span></p>

              <div v-if="item.updated_at" class="mt-2">
                Updated at {{ item.updated_at | moment("DD MMM YYYY")}}
                <span class="text-muted small">{{ item.updated_at | moment("h:mm A") }}</span>
                <p class="mb-0 small">by <span class="text-primary">{{ item.updatedBy.full_name }}</span></p>
              </div>
            </template>
          </a-table>
          <div :class="$style.tableFooter" class="text-center text-lg-left pb-4 pb-lg-0">
            <span class="text-muted">Showing <b>{{ posts.length }}</b> of <b>{{ new Intl.NumberFormat().format(pagination.total) }}</b> Posts</span>
          </div>
        </div>
      </b-col>
    </b-row>
  </Acl>
</template>
<script>
import { mapState } from 'vuex'
import Acl from '@/components/cleanui/system/ACL'
import moment from 'moment'

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      pagination: {},
      q: '',
      selectedPosts: [],
    }
  },
  created() {
    const vm = this
    vm.fetch()
    vm.$store.dispatch('editorChoices/FETCH', {
      portal_id: vm.settings.activePortal,
    }).then(() => {
      vm.selectedPosts = vm.editor_choices
    })
  },
  computed: {
    ...mapState(['settings']),
    ...mapState('post', {
      posts: state => state.data,
    }),
    ...mapState('editorChoices', {
      editor_choices: state => state.data,
    }),
    loading() {
      return this.$store.state.post.loading
    },
    columns() {
      // const vm = this
      const columns = [
        {
          title: 'Title',
          dataIndex: 'title',
          width: '40%',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'articleTitle' },
        },
        {
          title: 'Author',
          dataIndex: 'createdBy',
          className: 'text-gray-6',
          width: '20%',
          scopedSlots: { customRender: 'createdBy' },
        },
        {
          title: 'Categories',
          dataIndex: 'categories',
          width: '20%',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'categories' },
        },
        // {
        //   title: 'Tags',
        //   dataIndex: 'tags',
        //   className: 'text-gray-6',
        //   width: '10%',
        //   scopedSlots: { customRender: 'tags' },
        // },
        {
          title: 'Date',
          dataIndex: 'date',
          width: '20%',
          className: 'text-gray-6',
          scopedSlots: {
            customRender: 'date',
          },
        },
      ]
      return columns
    },
  },
  methods: {
    onDateChange(date, dateString, setSelectedKeys) {
      this.filters.published_at = [moment(dateString[0], 'DD-MM-YYYY'), moment(dateString[1], 'DD-MM-YYYY')]
      setSelectedKeys(dateString)
    },
    onSelectChange(selectedPosts) {
      const vm = this
      vm.selectedPosts = selectedPosts
      vm.$store.commit('editorChoices/SET_STATE', {
        form: {
          portal_id: vm.settings.activePortal,
          post_id: selectedPosts,
        },
      })
    },
    // Filter & Search
    search() {
      const vm = this
      vm.fetch()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      // console.log(selectedKeys, dataIndex)
      confirm()
      // this.filters.published_at = [moment(), moment()]
    },
    handleReset(clearFilters) {
      clearFilters()
      this.filters.published_at = []
    },
    fetch(payload = {}) {
      const vm = this
      vm.$store.dispatch('post/FETCH', {
        portal_id: vm.settings.activePortal,
        q: vm.q,
        ...payload,
      }).then((res) => {
        const pagination = {
          ...vm.pagination,
          current: Number(res.data.page),
          total: Number(res.data.total),
        }
        vm.pagination = pagination
      })
    },
    tableChanged(pagination, filters, sorter) {
      // console.log('table changed', filters)
      const vm = this
      vm.fetch({
        page: pagination.current,
        perPage: pagination.pageSize,
      })
    },
  },
  watch: {
    'settings.activePortal': function() {
      const vm = this
      vm.fetch()
      vm.$store.dispatch('editorChoices/FETCH', {
        portal_id: vm.settings.activePortal,
      }).then(() => {
        vm.selectedPosts = vm.editor_choices
      })
    },
  },
  destroyed() {
    this.$store.commit('post/CLEAR_DATA')
  },
  components: {
    Acl,
  },
}
</script>
<style lang="scss" module>
// Import mixins
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/mixins";

.table {
  td {
    vertical-align: top;

    h5 {
      font-size: 16px;
      margin-bottom: 2px;
    }
  }
  &Footer {
    @include media-breakpoint-up(lg) {
      margin-top: -45px;
      margin-bottom: 45px;
    }
  }
}
</style>
